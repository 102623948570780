<script>
import FormHandler from "~/mixins/formHandler";
import Rental from "~/modules/ask/services/Rental";

export default {
  name: "CancelEdit",
  emits: ['change'],
  data () {
    return {
      reason: '0',
      edit: {
        reason: '0',
        otherReason: '',
        message: ''
      }
    }
  },
  computed: {
  },
  methods: {
    change () {
      console.log('change')
      this.$emit('change', this.edit)
    }
  }
}
</script>

<template>
  <el-form label-position="top" size="large">
    <el-radio-group v-model="edit.reason" class="vertical-radio-list" @change="change">
      <el-radio label="0" size="large" class="responsive-radio">Votre matériel n’est pas disponible sur cette période</el-radio>
      <el-radio label="1" size="large">Vous n’êtes pas disponible sur cette période</el-radio>
      <el-radio label="2" size="large">Autre raison</el-radio>
    </el-radio-group>
    <el-form-item label="Raison" v-if="edit.reason == 2">
      <el-input v-model="edit.otherReason" placeholder="Raison" @change="change"></el-input>
    </el-form-item>
    <el-form-item :label="'Laissez un message :'">
      <el-input v-model="edit.message" type="textarea" placeholder="Message" @change="change"></el-input>
    </el-form-item>
  </el-form>
</template>

<style scoped>
</style>