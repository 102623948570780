<script>
import MediaEdit from "~/modules/media/components/MediaEdit.vue";
import BankValidationBehavior from "~/modules/payment/mixins/BankValidationBehavior.js";
import SelectNationality from "~/modules/payment/components/SelectNationality.vue";
import InputCity from "~/components/parameters/inputCity.vue";
import BankValidationNotifications from "~/modules/payment/components/BankValidationNotifications.vue";

export default {
  name: "BankValidationBeneficiary",
  components: {BankValidationNotifications, InputCity, SelectNationality, MediaEdit},
  mixins: [BankValidationBehavior],
  data () {
    return {
      uboDetailsErrors: {},
      edit: {
        isBeneficiary: true,
        beneficiary: [
          {
            firstname: '',
            lastname: '',
            birthdate: '',
            nationality: '',
            address: null,
          }
        ],
        medias: []
      },
      newBeneficiary: {
        nom: '',
        prenom: '',
        birthdate: '',
        nationality: '',
        adresse: '',
      },
      form: {
        identity: '',
        lastname: '',
        birthdate: '',
        quantity: ''
      }

    }
  },
  computed: {
    isBeneficiarySubmit () {
      return this.isSubmit('ubo')
    },
    formKey () {
      return 'beneficiary'
    },
  },
  methods: {
    addBeneficiary () {
      this.edit.beneficiary.push({
        active: 0,
        firstname: '',
        lastname: '',
        birthdate: '',
        nationality: '',
        address: null,
      })
    },
    async onFormSubmit () {
      await this.sendForm()
      if (this.$route && this.$route.name !== 'BankValidation') {
        let step = this.getNextStep('beneficiary')
        this.$router.push(step.to)
      }
    },
    transformHook (edit) {
      return {
        isBeneficiary: edit.isBeneficiary,
        ubos: edit.beneficiary
      }
    },
  }
}
</script>

<template>
  <div class="product-creation-form-header">
    <h3>{{ $t('shop.settings.bank-validation.steps.beneficiary.title') }}</h3>
    <p class="label-info">{{ $t('shop.settings.bank-validation.steps.beneficiary.description') }}</p>
  </div>
  <el-form
      :model="form"
      label-width="300px"
      label-position="left"
      size="large"
      class="custom_form_lightyshare"
  >
    <BankValidationNotifications v-if="ModelEntity && ModelEntity.uboKycDocuments" :document="ModelEntity.uboKycDocuments" :details-errors="uboDetailsErrors"/>
    <div v-if="!isBeneficiarySubmit">
      <el-form-item label="firstname">
        <template #label>
          <div>
            <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.beneficiary.effectif.label') }}</h4>
          </div>
        </template>
        <div class="mb-2 flex items-center text-sm">
          <el-radio-group v-model="edit.isBeneficiary" class="ml-4">
            <el-radio :label="true" size="large">{{ $t('shop.settings.bank-validation.steps.beneficiary.effectif.yes') }}</el-radio>
            <el-radio :label="false" size="large">{{ $t('shop.settings.bank-validation.steps.beneficiary.effectif.no') }}</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <div v-for="(b, k) in edit.beneficiary">
        <el-form-item label="firstname">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.beneficiary.firstname-label') }}</h4>
            </div>
          </template>
          <el-input v-if="k === 0" v-model="b.firstname" :placeholder="$t('shop.settings.bank-validation.steps.beneficiary.firstname-placeholder')" size="large" />
          <el-input v-if="k !== 0" v-model="b.firstname" :placeholder="$t('shop.settings.bank-validation.steps.beneficiary.new-beneficiary.firstname-placeholder')" size="large" />
        </el-form-item>
        <el-form-item label="lastname">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.beneficiary.lastname-label') }}</h4>
            </div>
          </template>
          <el-input v-if="k === 0" v-model="b.lastname" :placeholder="$t('shop.settings.bank-validation.steps.beneficiary.lastname-placeholder')" size="large" />
          <el-input v-if="k !== 0" v-model="b.lastname" :placeholder="$t('shop.settings.bank-validation.steps.beneficiary.new-beneficiary.lastname-placeholder')" size="large" />
        </el-form-item>
        <el-form-item label="birthday">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.beneficiary.birthday-label') }}</h4>
            </div>
          </template>
          <el-input v-model="b.birthdate" placeholder="16/09/1987" size="large" />
        </el-form-item>
        <el-form-item label="nationality">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.beneficiary.nationality-label') }}</h4>
            </div>
          </template>
          <SelectNationality v-model="b.nationality" size="large" />
        </el-form-item>
        <el-form-item label="address">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.beneficiary.home-address') }}</h4>
            </div>
          </template>
          <input-city
              name="siege"
              v-model="b.address"
              size="large"
              class="city-search"
              placeholder="24 rue mouftard 75008 Paris"
          ></input-city>
        </el-form-item>
      </div>
      <el-form-item label="address">
        <template #label>
          <div>
            <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.beneficiary.more-beneficiary.label') }}</h4>
          </div>
        </template>
        <el-button size="large" type="info" @click="addBeneficiary">{{ $t('shop.settings.bank-validation.steps.beneficiary.more-beneficiary.button') }}</el-button>
      </el-form-item>

    </div>
    <div class="actions">
      <el-button @click="onFormSubmit" :loading="formLoading" size="large" class="btn btn-primary">{{ $t('shop.settings.bank-validation.steps.buttons.next') }}</el-button>
    </div>
  </el-form>
</template>

<style scoped>

</style>